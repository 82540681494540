@import url('https://fonts.googleapis.com/css2?family=Syne+Mono&display=swap');

* {
    font-family: 'Syne Mono', monospace;
    font-size: 1.2rem;
    color: #201c1c;
    transition: all 0.5s ease;
}

html, body {
    margin: 0;
    padding: 0;
    background: #655d58;
}

html::-webkit-scrollbar-track
{
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
	background-color: transparent;
}

html::-webkit-scrollbar
{
	width: 10px;
	background-color: transparent;
}

html::-webkit-scrollbar-thumb
{
	background-color: rgba(#000000, .8);
	border: 2px solid #655d58;
}