header {

    .hero {
        width: 100%;
        height: 100vh;

        display: flex;
        // justify-content: center;
        align-items: center;

        background-image: url('../assets/hero_background.png');
        background-attachment: scroll;
        background-size: cover;
        background-position: bottom;
        background-position-x: center;
        background-repeat: no-repeat;

        .hero-text {
            margin-left: 100px;
            width: 600px;
            height: 200px;

            
            * {
                width: 100%;
                font-size: 2rem;
                text-align: center;
                color: #d7c9ba;
                text-shadow: 2px 4px 3px rgba(0,0,0,0.3);
            }
        }

    }
}

@include media-breakpoint-down(lg) { 
    header {
        .hero {
            width: 100%;

            .hero-text {
                padding: 0 20px;
                margin: 0 20px;
                width: calc(100% - 40px);
                
                * {
                    font-size: 1.5rem;
                }
            }
        }
    }
}