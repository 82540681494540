header {
    nav {
    
        // position: absolute !important;
        width: 100%;
        background: rgba(#3e3937, 0.7);
    
        .logo {
            padding: 10px 10px;
        }

        .nav-item:hover {
            text-decoration: underline;
        }
    }
}

@include media-breakpoint-down(md) { 
    header {
        nav {
            .navbar-brand {
                font-size: 1rem;
            }
        }
    }
}

@include media-breakpoint-up(lg) { 
    header {
        nav {
            .navbar-brand {
                font-size: 1.5rem;
            }
        }
    }
}