main {

    .portfolio {
        background-image: url('../assets/mural-left.png'), url('../assets/mural-right.png');
        background-position: left bottom, right bottom;
        background-repeat: no-repeat, no-repeat;
        background-size: contain, contain;

        


        .portfolio-cards {
            
            min-height: 500px;

            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            justify-content: space-around;
            
            .portfolio-card {
                flex-grow: 1;
                max-width: 400px;

                padding: 20px;

                img {
                    width: 100%;
                    max-width: 300px;
                    display: block;
                    margin: auto;
                    border-radius: 50%;
                    border: dashed rgba(#000, .5) 1.5px;
                    filter: blur(1px);
                    opacity: .9;
                }

                img:hover {
                    filter: none;
                    opacity: 1;
                }

                h2 {
                    margin: 10px 0;
                    padding: 15px 0;
                    text-align: center;
                    background: rgba(255, 255, 255, .1);

                    border: dashed rgba(#000, .5) 1.5px;
                }
            }
        }

        .social-icons {
            float: left;
            margin: 50px 80px;
            width: 360;

            h2 {
                width: 100%;
                text-align: center;
            }

            .icon {
                display: inline-block;
                width: 80px;
                height: 80px;
                border-radius: 50%;
                background: #3e3937;
                border: dashed rgba(#000, .5) 2px;
                margin: 0 10px;

                img {
                    display: block;
                    margin: 15px auto;
                    height: 50px;
                }

            }
        }
    }

}

@include media-breakpoint-down(lg) { 
    main {
        .portfolio {
            background-image: url('../assets/mural-left.png');
            background-position: left bottom;
            background-repeat: no-repeat;
            background-size: contain;
        }

        .social-icons {
            margin: 20px 0 !important;
        }


    }
}



@include media-breakpoint-down(md) { 
    main {
        .portfolio {
            background-image: url('../assets/mural-left.png');
            background-position: center;
            background-repeat: no-repeat;
            background-size: cover;
        }
    }
}

